<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <!-- <article-list @handleClick="articleClick" :data="dataList1"></article-list> -->
      <div style="display: flex;justify-content: center; ">
        <div style=" width: 80%;">
          <el-table :data="dataList1" border>
            <el-table-column fixed prop="fileName" label="文献名称"></el-table-column>
            <el-table-column prop="fileType" label="文献类型" width="120">
              <template slot-scope="scope">{{ fileTypeName(scope.row) }}</template>
            </el-table-column>

            <el-table-column label="下载" width="100">
              <template slot-scope="scope">
                <div @click="handleClick(scope.row)" style="cursor: pointer;">文献下载</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="footer">
            <div class="left">共 {{total1}} 条</div>

            <el-pagination
              background
              layout="prev, pager, next"
              :total="total1"
              :current-page.sync="queryParams1.pageNum"
              :page-size="queryParams1.pageSize"
              @current-change="getList1"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TopFilter from '../../components/topFilter.vue'

export default {
  name: 'market',
  components: {
    TopFilter
  },
  data() {
    return {
      dataList1: [],
      queryParams1: {
        pageNum: 1,
        pageSize: 10,
      },
      // 总条数
      total1: 0,
    }
  },
  computed: {
  },
  created() {

  },
  mounted() {
    this.getList1()
  },
  methods: {
    getList1() {
      const params = {
        ...this.queryParams1,
      }
      this.$api.share.filesList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList1 = data.rows;
          this.total1 = data.total;
        }
      })
    },
    fileTypeName(row) {
      const type = `${row.fileType}`
      const typeDic = {
        '1': '通知公告',
        '2': '政策文件',
        '3': '分类名称',
        '4': '其他'
      }
      return typeDic[type] ?? '--'
    },
    handleClick(row) {
      if (row.fileUrl) {
        window.open(row.fileUrl)
      }
      else {
        this.$message.error('无效的下载地址');
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/.el-table th.el-table__cell {
  background-color: #013fad;
  color: #fff;
}
</style>
